const getNumberSuffix = (num: number) => {
  if (num >= 11 && num <= 13) {
    return "th";
  }

  const lastDigit = num % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const padZero = (num: number) => (num < 10 ? `0${num}` : num.toString());
export const convertUnixTimeDate = (unixTime: any) => {
  const expirationDate = new Date(unixTime * 1000);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const day = expirationDate.getDate();
  const monthIndex = expirationDate.getMonth();
  const month = months[monthIndex];
  const year = expirationDate.getFullYear();
  const hours = expirationDate.getHours();
  const minutes = expirationDate.getMinutes();
  const suffix = getNumberSuffix(day);

  const formattedDate = `${hours}:${padZero(minutes)} ${month} ${day}${suffix}, ${year}`;

  return formattedDate;
};


export const daysRemainingFromNow = (unixTime: any) => {
  const secondsInADay = 86400;
  const currentUnixTime = Math.floor(Date.now() / 1000);
  const remainingSeconds = unixTime - currentUnixTime;
  const remainingDays = remainingSeconds / secondsInADay;

  return remainingDays;
}



export const convertUnixToDays = (unixTime: any) => {
  const secondsInADay = 86400;
  const numberOfDays = unixTime / secondsInADay;
  return numberOfDays;
}


