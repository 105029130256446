import LuckyCat from "../../components/Lucky";
import {
  EnvelopeDecor,
  NftsButtonMint,
  NftsContainer,
  NftsContent,
  NftsEffect,
  NftsEnvelope,
  NftsEnvelopeMint,
  NftsFrame,
  NftsImage,
  NftsPocket,
  NftsRow,
  NftsTitle,
  NftsWrapper,
} from "./styled";
import title from "../../assets/Nfts/nfts_title.png";
import TimeCount from "./Time";
import button_mint from "../../assets/Nfts/button_mint.png";
import pocket from "../../assets/Nfts/pocket.png";
import envelop_mint from "../../assets/Nfts/mint_envelop.png";
import { useEffect, useState } from "react";
import nfts_image from "../../assets/Nfts/nfts_mint.png";
import useMintNft from "../../Hooks/useMintNft";
import usePriceMint from "../../Hooks/usePriceMint";
import { ethers, formatEther } from "ethers";
import useCatchMint from "../../Hooks/useCatchMint";
import { useAccount, useBalance, useChainId } from "wagmi";
import toast from "react-hot-toast";
import ABI_MINT_NFT from "../../contract/ABI/ABI_NFT.json";
import useTransactionHash from "../../Hooks/useTransactionHash";
import { shortenAddress } from "../../utils/addressUser";
import { convertUnixTimeDate } from "../../utils/convertTime";
import Typewriter from "typewriter-effect";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { switchNetwork } from "@wagmi/core";
import useTotalSupply from "../../Hooks/useTotalSupply";
import useTokenIdCurrent from "../../Hooks/useTokenIdCurrent";

const Nfts = () => {
  const [mint, setMint] = useState(false);
  const [envelope, setEnvelope] = useState(false);
  const [envelopeImage, setEnvelopeImage] = useState(false);
  const [endMint, setEndMint] = useState(false);
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  // Get wallet balance
  const walletBalance: any = useBalance({
    address: address,
  });

  // get price mint
  const priceMint: any = usePriceMint();
  const convertPriceMint =
    priceMint?.data && formatEther(priceMint?.data).toString();

  // catch address is already mint
  const catchAddressMint: any = useCatchMint(address);

  // get total supply
  const totalSupplies: any = useTotalSupply();

  // get current nft minted
  const currentNftMinted: any = useTokenIdCurrent();

  // Handle switch chain
  const chainId = useChainId();
  const handleChangeNetwork = async () => {
    await switchNetwork({
      chainId: 56,
    });
  };

  const [stateCatchAddress, setStateCatchAddress] = useState(false);

  const { writeAsync: buyBoxWrite } = useMintNft(priceMint?.data);

  const [stateTxReceipt, setStateTxReceipt] = useState<any>();

  const [clickedMint, setClickedMint] = useState(false);

  const handleMint = async () => {
    setClickedMint(true);
    try {
      if (formatEther(walletBalance?.data.value) >= convertPriceMint) {
        await buyBoxWrite?.().then(async (txReceipt: any) => {
          setStateTxReceipt(txReceipt.hash);
        });
        setStateCatchAddress(true);
        setMint(true);
      } else {
        toast.error("You don't have enough balance");
        setClickedMint(false);
      }
    } catch (err) {
      toast.error("You've rejected");
      setClickedMint(false);
    }
  };

  useEffect(() => {
    if (address) {
      setClickedMint(false);
    }
  }, [address]);

  const handleClickEnvelope = () => {
    setEnvelope(true);
    setTimeout(() => {
      setEnvelopeImage(true);
    }, 1500);
    setTimeout(() => {
      setEndMint(true);
    }, 3000);
    setTimeout(() => {
      setMint(false);
    }, 4000);
  };

  const { data: dataHash } = useTransactionHash(stateTxReceipt);

  const [timeBuy, setTimeBuy] = useState(0);
  const [addressBuy, setAddressBuy] = useState("");
  const [addressReceiveID, setAddressReceiveID] = useState(0);

  useEffect(() => {
    if (dataHash) {
      const iface = new ethers.Interface(ABI_MINT_NFT);
      const parsed2 = iface.parseLog(dataHash?.logs[2]);
      setTimeBuy(Number(parsed2?.args[2]));
      setAddressBuy(parsed2?.args[0]);
      setAddressReceiveID(Number(parsed2?.args[1]));
    }
  }, [dataHash]);

  return (
    <>
      <NftsContainer>
        <NftsWrapper>
          <NftsEnvelopeMint
            className={envelope ? "open" : mint ? "mint" : ""}
            onClick={() => {
              handleClickEnvelope();
            }}
          >
            <EnvelopeDecor className={endMint ? "hidden" : ""} />
            <img
              className={endMint ? "hidden" : ""}
              src={envelop_mint}
              alt="envelop_mint"
            />
            <p>Click on the envelope</p>
            <NftsImage
              className={
                envelopeImage && !endMint
                  ? "appear"
                  : endMint && envelopeImage
                  ? "appear end"
                  : ""
              }
            >
              <img src={nfts_image} alt="nfts" />
            </NftsImage>
            <NftsEffect className={endMint ? "effect" : ""} />
          </NftsEnvelopeMint>
          <NftsFrame className={mint ? "mint" : ""}>
            <NftsTitle>
              <img src={title} alt="title" />
            </NftsTitle>
            {!endMint ? (
              <NftsContent>
                <NftsRow>
                  <p>
                    Total Supplies:{" "}
                    {totalSupplies?.data
                      ? totalSupplies?.data?.toString()
                      : "188"}{" "}
                    NFTs
                  </p>
                </NftsRow>
                <NftsRow>
                  <p>Price: {convertPriceMint || 1.88} bnb</p>
                  {/* <p>Price: 1.88 bnb</p> */}
                </NftsRow>
                <NftsRow>
                  <p>
                    Supplies Minted:{" "}
                    {(currentNftMinted?.data &&
                      currentNftMinted?.data?.toString() - 1) ||
                      0}{" "}
                    NFTs
                  </p>
                </NftsRow>
                <NftsRow>
                  <p>Mint nft and get $maneko wl</p>
                </NftsRow>
                <NftsRow>
                  <p>once wallet can mint one nft</p>
                </NftsRow>
                <TimeCount />
              </NftsContent>
            ) : (
              <NftsContent>
                <NftsRow>
                  <p>
                    <span>Time bought: </span>
                    {convertUnixTimeDate(timeBuy)}
                  </p>
                </NftsRow>
                <NftsRow>
                  <p>
                    <span>Address bought: </span>
                    {shortenAddress(addressBuy)}
                  </p>
                </NftsRow>
                <NftsRow>
                  <p>
                    <span>Token ID: </span>
                    {addressReceiveID}
                  </p>
                </NftsRow>
                <NftsRow>
                  <a
                    href={`https://bscscan.com/tx/${stateTxReceipt}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Transaction hash: </span>
                    {shortenAddress(stateTxReceipt)}
                  </a>
                </NftsRow>
              </NftsContent>
            )}
          </NftsFrame>
          {(stateCatchAddress || catchAddressMint?.data) && !endMint ? (
            <p style={{ color: "#fff" }}>You're already minted</p>
          ) : (
            <NftsButtonMint
              disabled={clickedMint ? true : false}
              className={mint ? "mint" : endMint && !mint ? "mint" : ""}
              onClick={() => {
                address && chainId === 56
                  ? handleMint()
                  : address && chainId !== 56
                  ? handleChangeNetwork()
                  : open();
              }}
            >
              {clickedMint ? (
                <Typewriter
                  options={{
                    strings: ["Loading..."],
                    autoStart: true,
                    loop: true,
                  }}
                />
              ) : (
                <img loading="lazy" src={button_mint} alt="mint" />
              )}
            </NftsButtonMint>
          )}
          <NftsPocket
            className={mint ? "mint" : endMint && !mint ? "mint" : ""}
          >
            <NftsEnvelope />
            <img loading="lazy" src={pocket} alt="pocket" />
          </NftsPocket>
        </NftsWrapper>
      </NftsContainer>
      <LuckyCat />
    </>
  );
};

export default Nfts;
