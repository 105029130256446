import { useEffect, useState } from "react";
import {
  PreLoaderDoors,
  PreloaderCat,
  PreloaderContainer,
  PreloaderKey,
  PreloaderLatern,
  PreloaderTemple,
  PreloaderWrapper,
} from "./styled";
import LaternPattern from "../Latern";
import cat from "../../assets/Preloader/Cat.png";
import { BlossomGif } from "../../styled";
import gif from "../../assets/gif/bloss.gif";

const Preloader = ({ setRemovePreloader }: any) => {
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (preloader) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [preloader]);

  const handleOpenDoor = () => {
    setPreloader(false);
    setTimeout(() => {
      setRemovePreloader(true);
    }, 3000);
  };

  return (
    <PreloaderContainer className={!preloader ? "open" : ""}>
      {/* <BlossomGif className={!preloader ? "open" : ""}>
        <img loading="lazy" src={gif} alt="gif" />
      </BlossomGif> */}
      <PreloaderWrapper>
        <PreloaderLatern className={!preloader ? "open" : ""}>
          <LaternPattern pattern={2} />
          <LaternPattern pattern={2} />
        </PreloaderLatern>
        <PreloaderTemple />
        <PreloaderCat className={!preloader ? "open" : ""}>
          <img loading="lazy" src={cat} alt="cat" />
        </PreloaderCat>
        <PreLoaderDoors className={!preloader ? "open" : ""}>
          <div className="left"></div>
          <div className="right"></div>
        </PreLoaderDoors>
        <PreloaderKey
          className={!preloader ? "open" : ""}
          onClick={() => [handleOpenDoor()]}
        />
      </PreloaderWrapper>
    </PreloaderContainer>
  );
};

export default Preloader;
