import {
  MarqueeList,
  PartnerContainer,
  PartnerItem,
  PartnerTitle,
  PartnerWrapper,
} from "./styled";
import title from "../../../assets/Home/Partner/partner_title.png";
import partner_1 from "../../../assets/Home/Partner/partner_1.png";
import partner_2 from "../../../assets/Home/Partner/partner_2.png";
import partner_3 from "../../../assets/Home/Partner/partner_3.png";
import partner_4 from "../../../assets/Home/Partner/partner_4.png";
import partner_5 from "../../../assets/Home/Partner/partner_5.png";
import partner_6 from "../../../assets/Home/Partner/partner_6.png";
import partner_7 from "../../../assets/Home/Partner/partner_7.png";
import partner_8 from "../../../assets/Home/Partner/partner_8.png";
import partner_9 from "../../../assets/Home/Partner/partner_9.png";
import partner_10 from "../../../assets/Home/Partner/partner_10.png";
import partner_11 from "../../../assets/Home/Partner/partner_11.png";
import partner_12 from "../../../assets/Home/Partner/partner_12.png";
import partner_13 from "../../../assets/Home/Partner/partner_13.png";
import partner_14 from "../../../assets/Home/Partner/partner_14.png";
import partner_16 from "../../../assets/Home/Partner/partner_16.png";
import partner_17 from "../../../assets/Home/Partner/partner_17.png";
import partner_18 from "../../../assets/Home/Partner/partner_18.png";
import partner_19 from "../../../assets/Home/Partner/partner_19.png";
import partner_20 from "../../../assets/Home/Partner/partner_20.png";
import partner_21 from "../../../assets/Home/Partner/partner_21.png";
import partner_22 from "../../../assets/Home/Partner/partner_22.png";
import partner_23 from "../../../assets/Home/Partner/partner_23.png";
import partner_24 from "../../../assets/Home/Partner/partner_24.png";
import partner_25 from "../../../assets/Home/Partner/partner_25.png";

import Marquee from "react-fast-marquee";
import decor from "../../../assets/Home/Partner/marquee_decor.png";

const Partner = () => {
  return (
    <PartnerContainer>
      <PartnerWrapper>
        <PartnerTitle>
          <img loading="lazy" src={title} alt="title" />
        </PartnerTitle>
      </PartnerWrapper>
      <MarqueeList>
        <Marquee direction="right">
          {marqueeData.map((item, index) => {
            return (
              <PartnerItem key={index}>
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img loading="lazy" src={decor} alt="decor" />
                  <img loading="lazy" src={item.img} alt="logo" />
                </a>
              </PartnerItem>
            );
          })}
        </Marquee>
      </MarqueeList>
      <MarqueeList>
        <Marquee direction="left">
          {marqueeData_2.map((item, index) => {
            return (
              <PartnerItem key={index}>
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img loading="lazy" src={decor} alt="decor" />
                  <img loading="lazy" src={item.img} alt="logo" />
                </a>
              </PartnerItem>
            );
          })}
        </Marquee>
      </MarqueeList>
    </PartnerContainer>
  );
};

const marqueeData = [
  {
    img: partner_1,
    href: "#",
  },
  {
    img: partner_2,
    href: "#",
  },
  {
    img: partner_3,
    href: "https://www.dexview.com/bsc/0xDA7C2d9c977ce750438Ea3f7e81134aE115A1f35",
  },
  {
    img: partner_4,
    href: "#",
  },
  {
    img: partner_5,
    href: "#",
  },
  {
    img: partner_6,
    href: "https://www.pinksale.finance/launchpad/0x24b6296a601aA62f5b78608ed1F1eA06A01Db667?chain=BSC",
  },
  {
    img: partner_3,
    href: "https://www.dexview.com/bsc/0xDA7C2d9c977ce750438Ea3f7e81134aE115A1f35",
  },
  {
    img: partner_4,
    href: "#",
  },
];

const marqueeData_2 = [
  {
    img: partner_7,
    href: "#",
  },
  {
    img: partner_8,
    href: "#",
  },
  {
    img: partner_9,
    href: "#",
  },
  {
    img: partner_10,
    href: "#",
  },
  {
    img: partner_11,
    href: "#",
  },
  {
    img: partner_12,
    href: "#",
  },
  {
    img: partner_13,
    href: "#",
  },
  {
    img: partner_14,
    href: "#",
  },
  {
    img: partner_16,
    href: "#",
  },
  {
    img: partner_17,
    href: "#",
  },
  {
    img: partner_18,
    href: "#",
  },
  {
    img: partner_19,
    href: "#",
  },
  {
    img: partner_20,
    href: "#",
  },
  {
    img: partner_21,
    href: "#",
  },
  {
    img: partner_22,
    href: "#",
  },
  {
    img: partner_23,
    href: "#",
  },
  {
    img: partner_24,
    href: "#",
  },
  {
    img: partner_25,
    href: "#",
  },
];

export default Partner;
