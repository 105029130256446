import {
  FrameCircle,
  FrameContract,
  FramePrice,
  FrameSubTitle,
  FrameValue,
  TokenomicCloud,
  TokenomicContainer,
  TokenomicContent,
  TokenomicFrame,
  TokenomicLatern,
  TokenomicTitle,
  TokenomicWrapper,
  ValueItem,
} from "./styled";
import title from "../../../assets/Home/Tokenomic/tokenomic_title.png";
import icon from "../../../assets/Home/Tokenomic/frame_icon.png";
import subtitle from "../../../assets/Home/Tokenomic/tokenomic_subtitle.png";
import copy from "../../../assets/Home/Tokenomic/copy.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import LaternPattern from "../../../components/Latern";
import { ContextProviderWrapper } from "../../../components/Context";
import { useContext } from "react";

const Tokenomic = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <TokenomicContainer id="token">
      <TokenomicWrapper>
        {!isMobile && (
          <TokenomicCloud>
            <div></div>
            <div></div>
          </TokenomicCloud>
        )}
        <TokenomicFrame>
          {!isMobile && (
            <TokenomicLatern>
              <LaternPattern pattern={2} />
              <LaternPattern pattern={2} />
            </TokenomicLatern>
          )}
          <TokenomicTitle>
            <img loading="lazy" src={title} alt="title" />
          </TokenomicTitle>
          <TokenomicContent>
            <FrameCircle>
              {/* <img loading="lazy" src={icon} alt="icon" /> */}
            </FrameCircle>
            <FrameSubTitle>
              <img loading="lazy" src={subtitle} alt="subtitle" />
            </FrameSubTitle>
            <FramePrice>888,888,000,000,000</FramePrice>
            <FrameContract>
              <a
                href="https://bscscan.com/token/0xda7c2d9c977ce750438ea3f7e81134ae115a1f35"
                target="_blank"
                rel="noreferrer"
              >
                <p>0xDA7C2d9c977ce750438Ea3f7e81134aE115A1f35</p>
              </a>
              <CopyToClipboard
                text={"0xDA7C2d9c977ce750438Ea3f7e81134aE115A1f35"}
                onCopy={() => {
                  toast.success("Copied !!", {
                    style: {
                      fontFamily: "Arial",
                      fontWeight: "bold",
                    },
                  });
                }}
              >
                <img loading="lazy" src={copy} alt="copy" />
              </CopyToClipboard>
            </FrameContract>
            <FrameValue>
              <ValueItem>
                <p>Tax: 3% Buy/Sell</p>
              </ValueItem>
              <ValueItem>
                <p>Tax: 3% Buy/Sell</p>
              </ValueItem>
            </FrameValue>
          </TokenomicContent>
        </TokenomicFrame>
      </TokenomicWrapper>
    </TokenomicContainer>
  );
};

export default Tokenomic;
