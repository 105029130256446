import { bsc } from "viem/chains";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import ABI_MINT_NFT from "../contract/ABI/ABI_NFT.json";
import { parseGwei } from "viem";

const useMintNft = (priceMint: any) => {
  const addressContract: any = process.env.REACT_APP_CONTRACT_MINT;
  const { config } = usePrepareContractWrite({
    address: addressContract,
    abi: ABI_MINT_NFT,
    functionName: "mint",
    chainId: bsc.id,
    value: priceMint && priceMint,
    gas: BigInt(1_000_000),
    gasPrice: parseGwei("3"),
    // onSettled: (data, error) => {
    //   console.log("Settled", { data, error });
    // },
  });

  const { data, isLoading, isSuccess, isError, error, writeAsync, write } =
    useContractWrite(config);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    writeAsync,
    write,
  };
};

export default useMintNft;
