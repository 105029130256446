import { useMotionValueEvent, useScroll } from "framer-motion";
import KeyvisualContent from "./Content";
import { KeyvisualLaternLight } from "./Content/styled";
import Decoration from "./Decoration";
import {
  KeyvisualContainer,
  KeyvisualInner,
  KeyvisualWrapper,
} from "./styled";
import { useRef, useState } from "react";
import LaternPattern from "../../../components/Latern";

const Keyvisual = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const [progressValue, setProgressValue] = useState(0);

  useMotionValueEvent(scrollYProgress, "change", (latest: any) => {
    const convertValue: any = Number(latest.toFixed(1));
    if (convertValue) {
      setProgressValue(convertValue);
    }
  });

  return (
    <KeyvisualContainer ref={ref}>
      <KeyvisualLaternLight>
        <LaternPattern pattern={4} />
        <LaternPattern pattern={3} />
        <LaternPattern pattern={3} />
        <LaternPattern pattern={4} />
      </KeyvisualLaternLight>
      <KeyvisualWrapper>
        <KeyvisualInner>
          <KeyvisualContent />
          <Decoration progressValue={progressValue} />
        </KeyvisualInner>
      </KeyvisualWrapper>
    </KeyvisualContainer>
  );
};

export default Keyvisual;
