import logo from "../../../../assets/Home/Keyvisual/Content/logo.png";
import {
  KeyvisualContentContainer,
  KeyvisualLogo,
  KeyvisualText,
} from "./styled";

const KeyvisualContent = () => {
  return (
    <KeyvisualContentContainer>
      <KeyvisualLogo>
        <img loading="lazy" src={logo} alt="logo" />
      </KeyvisualLogo>
      <KeyvisualText>
        欢迎来到“Maneko”——一个以招财猫为原型的创新虚拟货币项目，招财猫是日本和中国文化中幸运和财富的象征！我们将表情包的幽默与招财猫的独特魅力相结合，在加密货币世界中创造独特的体验。
      </KeyvisualText>
    </KeyvisualContentContainer>
  );
};

export default KeyvisualContent;
