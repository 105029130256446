import { FlowerDecorContainer, FlowerWrapper } from "./styled";
import flower from "../../../../assets/Home/Card/Decor/flower.png";

const FlowerDecor = () => {
  return (
    <FlowerDecorContainer>
      <FlowerWrapper>
        <img loading="lazy" src={flower} alt="flower" />
      </FlowerWrapper>
    </FlowerDecorContainer>
  );
};

export default FlowerDecor;
