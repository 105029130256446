import styled from "styled-components";

export const DecorationLeft = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 4;
  img {
    position: absolute;
    bottom: 0;
    transition: transform 3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .path_1 {
    width: 734px;
    height: 424px;
    &.animate {
      animation: pathTranslateLeft 3s linear infinite;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 604px;
      height: 324px;
    }
  }
  .path_2 {
    width: 555px;
    height: 354px;
    &.animate {
      animation: pathTranslateLeft 4s linear infinite;
    }
  }
  .path_3 {
    width: 445px;
    height: 545px;
    bottom: -272px;
    z-index: 3;
    &.animate {
      animation: pathTranslateLeft 5s linear infinite;
    }
    @media screen and (max-width: 767px) {
      width: 275px;
      height: 515px;
    }
  }
  .path_cloud_1 {
    width: 259px;
    height: 134px;
    top: -360px;
    left: 30px;
    &.animate {
      animation: pathTranslateLeft 2s linear infinite;
    }
  }
  .path_cloud_2 {
    width: 372px;
    height: 187px;
    left: 70px;
    bottom: 30px;
    &.animate {
      animation: pathTranslateLeft 4s linear infinite;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 252px;
      height: 127px;
    }
    @media screen and (max-width: 767px) {
      width: 130px;
      height: 80px;
    }
  }
  .path_fan {
    width: 0;
    height: 209px;
    bottom: -110px;
    z-index: 5;
    left: -105px;
    animation: rotate 15s linear infinite;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.active {
      width: 209px;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes pathTranslateLeft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
export const DecorationRight = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 4;
  img {
    position: absolute;
    bottom: 0;
    right: 0;
    transition: transform 3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .path_1 {
    width: 734px;
    height: 424px;
    &.animate {
      animation: pathTranslateRight 3s linear infinite;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 604px;
      height: 324px;
    }
  }
  .path_2 {
    width: 555px;
    height: 354px;
    &.animate {
      animation: pathTranslateRight 4s linear infinite;
    }
  }
  .path_3 {
    width: 445px;
    height: 545px;
    bottom: -272px;
    z-index: 3;
    &.animate {
      animation: pathTranslateRight 5s linear infinite;
    }
    @media screen and (max-width: 767px) {
      width: 275px;
      height: 515px;
    }
  }
  .path_cloud_1 {
    width: 259px;
    height: 134px;
    top: -360px;
    right: 30px;
    &.animate {
      animation: pathTranslateRight 2s linear infinite;
    }
  }
  .path_cloud_2 {
    width: 372px;
    height: 187px;
    right: 70px;
    bottom: 30px;
    &.animate {
      animation: pathTranslateRight 4s linear infinite;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 252px;
      height: 127px;
    }
    @media screen and (max-width: 767px) {
      width: 130px;
      height: 80px;
    }
  }
  .path_fan {
    width: 0;
    height: 209px;
    bottom: -110px;
    z-index: 5;
    right: -105px;
    animation: rotate 15s linear infinite;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.active {
      width: 209px;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes pathTranslateRight {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(3%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
