import { LaternContainer, LaternLight } from "./styled";
import latern_1 from "../../assets/Latern/latern_1.png";
import latern_2 from "../../assets/Latern/latern_2.png";
import latern_3 from "../../assets/Latern/latern_3.png";
import latern_4 from "../../assets/Latern/latern_4.png";

const LaternPattern = ({ pattern }: any) => {
  const handlePattern = () => {
    switch (pattern) {
      case 1:
        return <img loading="lazy" className="pattern_1" src={latern_1} alt="latern" />;
      case 2:
        return <img loading="lazy" className="pattern_2" src={latern_2} alt="latern" />;
      case 3:
        return <img loading="lazy" className="pattern_3" src={latern_3} alt="latern" />;
      case 4:
        return <img loading="lazy" className="pattern_4" src={latern_4} alt="latern" />;
      default:
        return <></>;
    }
  };

  return (
    <LaternContainer className={`pattern_wrapper_${pattern}`}>
      <LaternLight />
      {handlePattern()}
    </LaternContainer>
  );
};

export default LaternPattern;
