import styled from "styled-components";
import bg from "../../../assets/Home/Map/bg.png";
import decor_line from "../../../assets/Home/Map/decor_line.png";
import { LaternContainer } from "../../../components/Latern/styled";
import scroll_col from "../../../assets/Home/Map/scroll_col.png";
import scroll_bg from "../../../assets/Home/Map/scroll_bg.jpg";
import title_bg from "../../../assets/Home/Map/title_bg.png";
import map_item_before from "../../../assets/Home/Map/map_item_before.png";
import map_item_before_hover from "../../../assets/Home/Map/map_item_before_hover.png";
import light from "../../../assets/Home/Map/light.png";
import { PreloaderKey } from "../../../components/Preloader/styled";

export const MapContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
  padding: 40px 10px 230px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: url(${decor_line}) no-repeat center / 100% 100%;
    width: 100%;
    height: 38px;
    left: 0;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    padding-bottom: 100px;
    &::before,
    &::after {
      background-size: cover;
    }
  }
`;
export const MapWrapper = styled.div`
  ${PreloaderKey} {
    margin-top: -50px;
    z-index: 10;
  }
`;
export const MapTitle = styled.h2`
  mix-blend-mode: plus-lighter;
  max-width: 191px;
  width: 100%;
  img {
    max-width: 191px;
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    img {
      max-width: 150px;
    }
  }
`;
export const MapTop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  padding-top: 32px;
  ${LaternContainer} {
    position: absolute;
    top: -2px;
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(3) {
      right: 0;
    }
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 20px;
    &.open {
      margin-bottom: 120px;
    }
  }
`;
export const MapScrollContainer = styled.div`
  position: relative;
  z-index: 5;
  @media screen and (max-width: 1023px) {
    height: 360px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    &.open {
      height: 1300px;
    }
  }
`;
export const ScrollWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 220px;
  height: 596px;
  transition: all 1s ease;
  transform: rotate(-90deg);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    background: url(${scroll_col}) no-repeat center / 100% auto;
    width: 111px;
    height: 596px;
    margin-top: -298px;
    z-index: 2;
    pointer-events: none;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &.open {
    width: 1150px;
    transform: rotate(0);
  }
  @media screen and (max-width: 1023px) {
    &::before,
    &::after {
      width: 105px;
      height: 568px;
      margin-top: -284px;
    }
    &.open {
      transform: rotate(-90deg);
      width: 1220px;
      height: 390px;
      justify-content: center;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 120px;
    height: 356px;
    &::before {
      left: -90px;
    }
    &::after {
      right: -90px;
    }
    &.open {
      width: 1220px;
    }
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      width: 75px;
      height: 408px;
      margin-top: -207px;
    }
    &::before {
      left: -40px;
    }
    &::after {
      right: -40px;
    }
    &.open {
      width: 1350px;
    }
  }
`;
export const ScrollTitle = styled.h3`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${title_bg}) no-repeat center / 100% 100%;
  width: 139px;
  height: 42px;
  margin: 0 auto -15px;
  padding-bottom: 2px;
  color: #fff;
  font-size: 12px;
  z-index: 2;
`;
export const ScrollDescribe = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: radial-gradient(
    56.99% 62.94% at 50% 50%,
    #fdf5b7 0%,
    #f09b42 100%
  );
  color: #000;
  padding: 25px 15px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 14px;
  word-break: break-word;
`;
export const ScrollLight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url(${light}) no-repeat center / 100% auto;
  width: 40px;
  height: 40px;
  z-index: 2;
  mix-blend-mode: screen;
  transform: scale(0);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  &.small {
    width: 40px;
    height: 40px;
    bottom: 0;
    top: unset;
    left: unset;
    right: 0;
  }
  @media screen and (max-width: 767px) {
    &.small {
      right: 20px;
      bottom: 20px;
    }
  }
`;
export const ScrollItem = styled.div`
  &.fadeItem {
    opacity: 0;
    visibility: hidden;
    &:nth-child(2) {
      z-index: 2;
      transform: translateX(-237px);
    }
    &:nth-child(3) {
      z-index: 3;
      transform: translateX(-474px);
    }
    &:nth-child(4) {
      z-index: 4;
      transform: translateX(-711px);
    }
  }
`;
export const ScrollList = styled.div`
  display: flex;
  align-items: center;
  width: 0;
  margin: 0 auto;
  height: 315px;
  padding: 30px 45px;
  background: url(${scroll_bg}) no-repeat center / cover;
  gap: 8px;
  border-top: 1px solid #ff630c;
  border-bottom: 1px solid #ff630c;
  transition: all 1s ease;
  &.open {
    width: 940px;
    padding: 30px 15px;
    & > div {
      width: 25%;
    }
  }
  & > div {
    position: relative;
    width: 0;
    min-height: 276px;
    padding: 5px;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
    transform: translateX(0);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      background: no-repeat center / 100% auto;
      width: 227px;
      height: 78px;
      margin-left: -113px;
      transition: opacity 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      z-index: 5;
      pointer-events: none;
    }
    &::before {
      bottom: -30px;
      background-image: url(${map_item_before});
      opacity: 1;
    }
    &::after {
      bottom: -28px;
      background-image: url(${map_item_before_hover});
      opacity: 0;
    }
    &:hover {
      ${ScrollLight} {
        transform: scale(1);
      }
      &::before {
        opacity: 1;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    &.open {
      & > div {
        min-width: 260px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    width: 120px;
    &.open {
      width: 1230px;
      gap: 20px;
      flex-direction: row-reverse;
      & > div {
        transform: rotate(90deg);
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 195px;
    &.open {
      gap: 80px;
      padding-left: 55px;
      padding-right: 55px;
      & > div {
        width: auto;
        min-width: 230px;
      }
    }
  }
`;
export const DescribeWrapper = styled.div`
  position: relative;
  min-height: 276px;
  display: flex;
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background-color: #c6151c;
    z-index: 0;
    border-radius: 14px;
  }
  @media screen and (max-width: 1023px) {
    min-height: 240px;
  }
`;
export const MapHint = styled.div`
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s ease;
  max-width: 305px;
  width: 100%;
  img {
    width: 100%;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  @media screen and (max-width: 767px) {
    bottom: -30px;
  }
`;
