import styled from "styled-components";
import bg from "../../../assets/Home/About/about_bg.png";
import about_frame from "../../../assets/Home/About/about_frame.png";
import decor_1 from "../../../assets/Home/About/decor_1.png";
import decor_2 from "../../../assets/Home/About/decor_2.png";
import fan_1 from "../../../assets/Home/About/fan_1.png";
import fan_2 from "../../../assets/Home/About/fan_2.png";
import leaf_1 from "../../../assets/Home/About/leaf_1.png";
import leaf_2 from "../../../assets/Home/About/leaf_2.png";
import cloud_1 from "../../../assets/Home/About/cloud_1.png";
import cloud_2 from "../../../assets/Home/About/cloud_2.png";
import cloud_3 from "../../../assets/Home/About/cloud_3.png";
import cloud_4 from "../../../assets/Home/About/cloud_4.png";
import item_bg from "../../../assets/Home/About/item_bg.png";

export const AboutContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    bottom: 0;
    width: 456px;
    height: 285px;
  }
  &::before {
    left: 0;
    background-image: url(${decor_1});
  }
  &::after {
    right: 0;
    background-image: url(${decor_2});
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    &::before,
    &::after {
      content: none
    }
  }
`;
export const AboutWrapper = styled.div`
  position: relative;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding-top: 120px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    bottom: 0;
    mix-blend-mode: color-dodge;
    z-index: 2;
  }
  &::before {
    left: -30px;
    width: 168px;
    height: 122px;
    background-image: url(${leaf_1});
  }
  &::after {
    right: 20px;
    width: 106px;
    height: 71px;
    background-image: url(${leaf_2});
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const AboutFrame = styled.div`
  position: relative;
  background: url(${about_frame}) no-repeat center / 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 622px;
  width: 100%;
  margin: 0 auto;
  padding: 140px 0 90px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    width: 92px;
    height: 137px;
  }
  &::before {
    top: 150px;
    right: -80px;
    background-image: url(${fan_1});
  }
  &::after {
    left: -50px;
    bottom: 50px;
    background-image: url(${fan_2});
  }
  @media screen and (max-width: 1023px) {
    max-width: 540px;
  }
  @media screen and (max-width: 767px) {
    background-size: cover;
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const AboutTitle = styled.h2`
  max-width: 305px;
  width: 100%;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: auto;
  }
`;
export const AboutDescribe = styled.p`
  max-width: 250px;
  width: 100%;
  color: #000;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;
export const AboutCloud = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
export const CloudItem = styled.div`
  position: absolute;
  background: no-repeat center / 100% auto;
  &:nth-child(1) {
    top: 60px;
    left: 100px;
    width: 242px;
    height: 136px;
    background-image: url(${cloud_1});
  }
  &:nth-child(2) {
    top: 50%;
    left: 50%;
    margin-left: -400px;
    margin-top: -100px;
    width: 179px;
    height: 114px;
    background-image: url(${cloud_2});
  }
  &:nth-child(3) {
    bottom: 80px;
    left: 50%;
    margin-left: 200px;
    width: 251px;
    height: 177px;
    background-image: url(${cloud_3});
  }
  &:nth-child(4) {
    right: -180px;
    top: 50%;
    margin-top: -85px;
    width: 472px;
    height: 170px;
    background-image: url(${cloud_4});
  }
`;
export const AboutLatern = styled.div`
  position: absolute;
  top: 174px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    position: relative;
    &:nth-child(1) {
      left: -10px;
    }
    &:nth-child(2) {
      right: -10px;
    }
  }
`
export const AboutLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 160px;
  gap: 4px;
  & > li {
    a {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: url(${item_bg}) no-repeat center / 100% auto;
      figure {
        max-width: 38px;
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
`