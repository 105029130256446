import styled from "styled-components";
import time_bg from '../../../assets/Nfts/time_bg.png'

export const TimeCountList = styled.ul`
  position: relative;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 55px;
    width: 100%;
    height: 56px;
    background: url(${time_bg}) no-repeat center / 100% auto;
    p {
      background: linear-gradient(130deg, #DDB263 10.42%, #DFB565 12.56%, #ECC974 28.97%, #F5D57D 44.67%, #F8DA81 60.37%, #F1D079 64.65%, #DFB566 70.36%, #C28B46 78.92%, #B67838 81.78%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      mix-blend-mode: plus-lighter;
      font-size: 22px;
      padding-bottom: 6px;
    }
  }
`