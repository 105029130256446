import styled from "styled-components";
import bg from "../../assets/Nfts/nfts_bg.jpg";
import frame_bg from "../../assets/Nfts/nfts_frame.png";
import pocket_before from "../../assets/Nfts/pocket_before.png";
import coin from "../../assets/Nfts/coin.svg";
import decor_1 from "../../assets/Home/Card/Decor/decor_1.png";
import decor_2 from "../../assets/Home/Card/Decor/decor_2.png";
import toss_coin_1 from "../../assets/gif/Nfts/toss_coin_1.gif";
import toss_coin_2 from "../../assets/gif/Nfts/toss_coin_2.gif";
import effect from "../../assets/gif/Nfts/effect.gif";
import envelop_decor from "../../assets/Nfts/mint_envelop_decor.png";

export const NftsContainer = styled.div`
  background: url(${bg}) no-repeat center / cover;
  min-height: 100vh;
  padding: 80px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const NftsWrapper = styled.div`
  position: relative;
  max-width: 1025px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    z-index: 5;
  }
  &::before {
    background-image: url(${decor_1});
    width: 313px;
    height: 320px;
    bottom: -120px;
    left: -120px;
    animation: movingBefore 3s linear infinite;
  }
  &::after {
    background-image: url(${decor_2});
    width: 390px;
    height: 309px;
    bottom: -120px;
    right: -140px;
    animation: movingAfter 3s linear infinite;
  }
  .Typewriter__wrapper {
    color: #fff;
    font-size: 18px;
  }
  .Typewriter__cursor {
    color: #fff;
  }
  @keyframes movingBefore {
    0% {
      transform: translate(0);
    }
    30% {
      transform: translate(10px, 10px);
    }
    60% {
      transform: translate(10px, 20px);
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes movingAfter {
    0% {
      transform: translate(0);
    }
    30% {
      transform: translate(10px, -10px);
    }
    60% {
      transform: translate(-10px, 20px);
    }
    100% {
      transform: translate(0);
    }
  }
  @media screen and (max-width: 1023px) {
    &::before {
      width: 183px;
      height: 210px;
      left: 0;
    }
    &::after {
      width: 250px;
      height: 209px;
      right: 20px;
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 80px 10px 120px;
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const NftsFrame = styled.div`
  position: relative;
  max-width: 545px;
  width: 100%;
  min-height: 220px;
  margin-bottom: 30px;
  margin-top: auto;
  background: url(${frame_bg}) no-repeat center / 100% 100%;
  transition: all 0.5s ease;
  &.mint {
    opacity: 0;
    visibility: hidden;
  }
  @media screen and (max-width: 767px) {
    min-height: 200px;
  }
`;
export const NftsTitle = styled.div`
  position: relative;
  max-width: 197px;
  width: 100%;
  margin: -60px auto 10px;
  img {
    width: 100%;
  }
`;
export const NftsRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  p,
  a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 19px;
    background: linear-gradient(
      130deg,
      #ddb263 10.42%,
      #dfb565 12.56%,
      #ecc974 28.97%,
      #f5d57d 44.67%,
      #f8da81 60.37%,
      #f1d079 64.65%,
      #dfb566 70.36%,
      #c28b46 78.92%,
      #b67838 81.78%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #262626;
    -webkit-text-fill-color: #ffff5d;
    &::before {
      content: "";
      position: relative;
      top: 3px;
      display: block;
      width: 14px;
      height: 14px;
      background: url(${coin}) no-repeat center / 100% auto;
    }
    span {
      color: #000;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
    p, a {
      font-size: 14px;
    }
  }
`;
export const NftsContent = styled.div`
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: 250px;
  }
`;
export const NftsButtonMint = styled.button`
  max-width: 142px;
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  img {
    width: 100%;
  }
  position: relative;
  transition: all 0.5s ease;
  &.mint {
    opacity: 0;
    visibility: hidden;
  }
`;
export const NftsPocket = styled.div`
  position: relative;
  max-width: 177px;
  width: 100%;
  margin-top: 140px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    width: 150px;
    height: 150px;
    z-index: 0;
  }
  &::before {
    top: -138px;
    left: 17px;
    background-image: url(${toss_coin_1});
  }
  &::after {
    left: 17px;
    top: -80px;
    background-image: url(${toss_coin_2});
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  transition: all 0.5s ease;
  &.mint {
    opacity: 0;
    visibility: hidden;
  }
`;
export const NftsEnvelope = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    width: 286px;
    height: 296px;
    z-index: 3;
  }
  &::before {
    bottom: 0;
    left: 50%;
    margin-left: -143px;
    background-image: url(${pocket_before});
  }
`;
export const EnvelopeDecor = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: url(${envelop_decor}) no-repeat center / 100% auto;
  width: 322px;
  height: 174px;
  transition: all 1s ease;
  z-index: 4;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
`;
export const NftsEnvelopeMint = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  img {
    position: relative;
    width: 322px;
    height: 400px;
    z-index: 2;
    transition: all 0.5s ease;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.05em;
  }
  &.mint {
    opacity: 1;
    visibility: visible;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    ${EnvelopeDecor} {
      transform: translateY(-172px) rotateX(185deg);
      z-index: 0;
    }
    p {
      opacity: 0;
      visibility: hidden;
    }
  }
  @media screen and (max-width: 767px) {
    bottom: 140px;
  }
`;
export const NftsImage = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  max-width: 316px;
  width: 100%;
  z-index: 0;
  height: 389px;
  img {
    width: 100%;
  }
  &.appear {
    animation: appearMint 4s;
    &.end {
      z-index: 5;
      transform: translateY(80px);
    }
  }
  @keyframes appearMint {
    0% {
      transform: scale(0.5);
    }
    20% {
      transform: translateY(-300px) scale(0.5);
    }
    50% {
      transform: scale(1);
      transform: translateY(80px);
    }
    100% {
      z-index: 5;
    }
  }
`;
export const NftsEffect = styled.div`
  position: absolute;
  bottom: 138px;
  left: 50%;
  background: url(${effect}) no-repeat center / 100% auto;
  width: 1200px;
  height: 684px;
  transform: translatex(-50%) rotate(180deg) scale(1.5);
  mix-blend-mode: plus-lighter;
  opacity: 0;
  border-radius: 44%;
  &.effect {
    opacity: 0.5;
  }
`;
