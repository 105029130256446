import FlowerDecor from "./Flower";
import {
  CardButton,
  CardContainer,
  CardImage,
  CardList,
  CardNote,
  CardTitle,
  CardWrapper,
  OverlayBg,
  OverlayCardContainer,
} from "./styled";
import card_1 from "../../../assets/Home/Card/Item/card_1.jpg";
import card_2 from "../../../assets/Home/Card/Item/card_2.jpg";
import card_3 from "../../../assets/Home/Card/Item/card_3.jpg";
import note from "../../../assets/Home/Card/Decor/note.svg";
import { ButtonCommon } from "../../../Layout/styled";
import { useEffect, useState } from "react";
import ScrollMap from "../Map/Scroll";
import { Link } from "react-router-dom";

const Card = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [openMap, setOpenMap] = useState(false);
  const [mapContent, setMapContent] = useState(false);
  const [fadeItem, setFadeItem] = useState(true);

  const handleOpenMap = () => {
    setOpenMap(true);
    setMapContent(true);
    setIsHidden(false);
    setTimeout(() => {
      setFadeItem(false);
    }, 1000);
  };

  const handleCloseMap = () => {
    setTimeout(() => {
      setOpenMap(false);
      setMapContent(false);
    }, 1000);
    setFadeItem(true);
    setTimeout(() => {
      setIsHidden(true);
    }, 1500);
  };

  useEffect(() => {
    if (!isHidden) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [isHidden]);

  return (
    <CardContainer>
      <FlowerDecor />
      <CardWrapper>
        <CardList>
          {cardData.map((item, index) => {
            return (
              <div key={index}>
                <CardTitle>{item.title}</CardTitle>
                <CardImage>
                  <img loading="lazy" src={item.img} alt={item.title} />
                </CardImage>
              </div>
            );
          })}
        </CardList>
        <CardButton>
          <Link to="/mint">
            <ButtonCommon className="big mint" />
          </Link>
        </CardButton>
        {/* <CardNote
          onClick={() => {
            handleOpenMap();
          }}
        >
          <img loading="lazy" src={note} alt="note" />
          How to buy
        </CardNote> */}
      </CardWrapper>
      {/* <OverlayCardContainer
        style={{
          opacity: !isHidden ? "1" : "0",
          visibility: !isHidden ? "visible" : "hidden",
        }}
      >
        <OverlayBg
          onClick={() => {
            handleCloseMap();
          }}
        />
        <ScrollMap
          openMap={openMap}
          mapContent={mapContent}
          fadeItem={fadeItem}
          scrollData={scrollData}
        />
      </OverlayCardContainer> */}
    </CardContainer>
  );
};

const cardData = [
  // {
  //   title: "CHEF",
  //   img: card_1,
  // },
  // {
  //   title: "TRIAD",
  //   img: card_2,
  // },
  {
    title: "THE RICH",
    img: card_3,
  },
];

const scrollData = [
  {
    title: "Create a Wallet",
    describe:
      "Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io",
  },
  {
    title: "Get $AVAX",
    describe:
      "Have $AVAX in your wallet to switch to $GEC. If you don’t have any $AVAX, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.",
  },
  {
    title: "GO TRADERJOE",
    describe:
      "Go to traderjoexyz.com/avalanche/trade on the browser inside your Metamask app. Connect your wallet. Select chain Avalanche C-Chain (43114). Paste the $MANEKO token address into the TraderJoe, select $MANEKO, and confirm. When Metamask prompts you for a wallet signature, sign.",
  },
  {
    title: "SWITCH INTO MANEKO",
    describe:
      "Switch $AVAX for $GEC. We have NO taxes, so set Slippage to any ratio you want and then click buy.",
  },
];

export default Card;
