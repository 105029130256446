import styled from "styled-components";
import footer_decor from '../../assets/Footer/footer_decor.png'
import footer_content from '../../assets/Footer/footer_content.png'
import bg from '../../assets/Home/Map/bg.png'

export const FooterContainer = styled.div`
  position: relative;
  padding: 120px 20px;
  background: #AF2623 url(${bg}) no-repeat center / cover;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(${footer_decor}) no-repeat center / cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @media screen and (max-width: 1023px) {
    padding: 65px 30px 100px;
  }
`;
export const FooterWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
export const FooterContent = styled.div`
  background: url(${footer_content}) no-repeat center / 100% auto;
  max-width: 510px;
  min-height: 223px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1023px) {
    max-width: 375px;
    min-height: 173px;
    margin-bottom: 5px;
  }
`
export const FooterSocials = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  & > li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 70px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    gap: 25px;
    & > li {
      a {
        width: 48px;
        height: 50px;
      }
    }
  }
`;
export const FooterCopyRight = styled.div`
  text-align: center;
  small {
    font-family: "Zhi Mang Xing", cursive;
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(130deg, #DDB263 10.42%, #DFB565 12.56%, #ECC974 28.97%, #F5D57D 44.67%, #F8DA81 60.37%, #F1D079 64.65%, #DFB566 70.36%, #C28B46 78.92%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 767px) {
    small {
      font-size: 18px;
    }
  }
`;
