import LaternPattern from "../../../components/Latern";
import { MapContainer, MapHint, MapTitle, MapTop, MapWrapper } from "./styled";
import title from "../../../assets/Home/Map/title.png";
import { useState } from "react";
// import { useInView } from "framer-motion";
import ScrollMap from "./Scroll";
import { PreloaderKey } from "../../../components/Preloader/styled";
import text_click from '../../../assets/Home/Map/text_click.png'

const Map = () => {
  // const ref = useRef<HTMLDivElement>(null);
  // const isInView = useInView(ref,{
  //   margin: "0px 100px -50px 0px"
  // });
  const [openMap, setOpenMap] = useState(false);
  const [mapContent, setMapContent] = useState(false);
  const [fadeItem, setFadeItem] = useState(true);

  const handleOpenMap = () => {
    setOpenMap(true);
    setMapContent(true);
    setTimeout(() => {
      setFadeItem(false);
    }, 1000);
  };

  // const handleCloseMap = () => {
  //   setTimeout(() => {
  //     setOpenMap(false);
  //     setMapContent(false);
  //   }, 1000);
  //   setFadeItem(true);
  // };

  // useEffect(() => {
  //   if (isInView) {
  //     setTimeout(() => {
  //       handleOpenMap();
  //     }, 1000);
  //   } else {
  //     handleCloseMap();
  //   }
  // }, [isInView]);

  return (
    <MapContainer id="map">
      <MapWrapper>
        <MapTop className={openMap ? "open" : ""}>
          <LaternPattern pattern={1} />
          <MapTitle>
            <img loading="lazy" src={title} alt="title" />
          </MapTitle>
          <LaternPattern pattern={1} />
        </MapTop>
        <div style={{ position: "relative" }}>
          <PreloaderKey
            className={openMap ? "open" : ""}
            onClick={handleOpenMap}
          />
          <ScrollMap
            openMap={openMap}
            mapContent={mapContent}
            fadeItem={fadeItem}
            scrollData={scrollData}
          />
          <MapHint className={openMap ? "hide" : ""}>
            <img src={text_click} alt="" />
          </MapHint>
        </div>
      </MapWrapper>
    </MapContainer>
  );
};

const scrollData = [
  {
    title: "Phase 1",
    describe: [
      "- 网站建设和社交媒体",
      "- Maneko NFT 发布",
      "- Pinksale 预售",
      "- 上市后营销",
    ],
  },
  {
    title: "Phase 2",
    describe: [
      "- 在 Pancakeswap 上市",
      "- DEX 趋势",
      "- 列出 CEX",
      "- 快速CMC/CG",
      "- 合作伙伴活动",
    ],
  },
  {
    title: "Phase 3",
    describe: ["- Tier1 CEX 上市", "- 质押Maneko", "- 招猫游戏发布"],
  },
  {
    title: "Phase 4",
    describe: [
      "- Maneko 交易平台",
      "- 兑换 Maneko",
      "- Maneko 钱包",
      "- Maneko 社交网络",
    ],
  },
];

export default Map;
