import styled from "styled-components";

export const AppContainer = styled.div<{ home?: boolean }>`
  min-height: 100vh;
  margin-top: ${({ home }) => (home ? "80px" : "0")};
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  @media screen and (min-width: 1024px) {
    width: ${({ home }) => (home ? "101%" : "100%")};
  }
`;
export const BlossomGif = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  mix-blend-mode: lighten;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: all 0.25s;
  &.open {
    opacity: 0;
    visibility: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;