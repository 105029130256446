import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  ActionVolumn,
  ButtonAudit,
  ButtonConnectWallet,
  ButtonHamburger,
  HeaderAction,
  HeaderBody,
  HeaderContainer,
  HeaderContent,
  HeaderInner,
  HeaderLinks,
  HeaderLogo,
  MobileAction,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import { Link, useLocation } from "react-router-dom";
import { ButtonCommon } from "../../Layout/styled";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import volumn from "../../assets/Header/volumn.svg";
import { shortenAddress } from "../../utils/addressUser";
import button_audit from "../../assets/Button/button_audit.png";

const Header = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [openHamburger, setOpenHamburger] = useState(false);
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  const truncateAddress =
    address &&
    `${address.substr(0, 4)}...${address.substr(address.length - 4)}`;

  return (
    <HeaderBody home={pathname === "/"}>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <img loading="lazy" src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <HeaderContent
          style={
            !isDesktop && openHamburger ? { transform: "translateX(0)" } : {}
          }
          className={!isDesktop ? "menu-fixed" : ""}
        >
          <HeaderInner>
            <HeaderLinks>
              {linkData.map((item, index) => {
                if (item.scrollTo) {
                  return (
                    <li key={index}>
                      <HashLink
                        smooth
                        to={item.href}
                        onClick={() => {
                          setOpenHamburger(false);
                        }}
                      >
                        {item.title}
                      </HashLink>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      {item.externalLink ? (
                        <>
                          <a target="_blank" rel="noreferrer" href={item.href}>
                            {item.title}
                          </a>
                        </>
                      ) : (
                        <>
                          <NavLink
                            onClick={() => setOpenHamburger(false)}
                            to={item.href}
                          >
                            {item.title}
                          </NavLink>
                        </>
                      )}
                    </li>
                  );
                }
              })}
            </HeaderLinks>
          </HeaderInner>
          <HeaderAction>
            {/* {isDesktop && (
              <ActionVolumn>
                <img loading="lazy" src={volumn} alt="volumn" />
              </ActionVolumn>
            )} */}
            {isDesktop && <ButtonAudit>
              <a
                href="https://app.solidproof.io/projects/maneki-neko?audit_id=941"
                target="_blank"
                rel="noreferrer"
              >
                <img src={button_audit} alt="audit" />
              </a>
            </ButtonAudit>}
            {pathname !== "/mint" ? (
              <ButtonCommon className="small" />
            ) : (
              <ButtonConnectWallet
                onClick={() => {
                  open();
                }}
              >
                {address ? (
                  <p>{shortenAddress(address)}</p>
                ) : (
                  <p>Connect Wallet</p>
                )}
              </ButtonConnectWallet>
            )}
          </HeaderAction>
        </HeaderContent>
        {!isDesktop && (
          <MobileAction>
            {/* <ActionVolumn>
              <img loading="lazy" src={volumn} alt="volumn" />
            </ActionVolumn> */}
            <ButtonAudit>
              <img src={button_audit} alt="audit" />
            </ButtonAudit>
            <ButtonHamburger
              className={openHamburger ? "active" : ""}
              onClick={() => {
                handleOpenHamburger();
              }}
            >
              <span></span>
              <span></span>
            </ButtonHamburger>
          </MobileAction>
        )}
      </HeaderContainer>
    </HeaderBody>
  );
};

const linkData = [
  {
    title: "Home",
    href: "/home",
    scrollTo: false,
    externalLink: false,
  },
  {
    title: "Tokenomics",
    href: "/#token",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "Roadmap",
    href: "/#map",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "Mint",
    href: "/mint",
    scrollTo: false,
    externalLink: false,
  },
  {
    title: "Docs",
    href: "https://maneko.gitbook.io/maneki-neko/",
    scrollTo: false,
    externalLink: true,
  },
];

export default Header;
