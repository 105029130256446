
import { useContractRead } from "wagmi";
import { bsc } from "wagmi/chains";
import ABI_MINT_NFT from "../contract/ABI/ABI_NFT.json";

function usePriceMint() {
  const addressToken: any = process.env.REACT_APP_CONTRACT_MINT;
  try {
    return useContractRead({
      address: addressToken,
      abi: ABI_MINT_NFT,
      functionName: "getPriceMint",
      chainId: bsc.id,
      // onSuccess(data) {
      //   console.log("Success", data);
      // },
    });
  } catch (error) {
    console.log(error);
  }
}

export default usePriceMint;
