import styled from "styled-components";

export const LaternLight = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -90px;
  margin-left: -110px;
  width: 228px;
  height: 228px;
  border-radius: 50%;
  z-index: -1;
  background: radial-gradient(
    49.97% 49.97% at 48.44% 52%,
    #ffe8a6 17%,
    #f8e2a4 20%,
    #cdbf96 38%,
    #aba38c 56%,
    #928f85 72%,
    #848380 87%,
    #7f7f7f 100%
  );
  @keyframes opacityLight {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const LaternContainer = styled.div`
  position: relative;
  animation: translate 5s linear infinite;
  mix-blend-mode: hard-light;
  .pattern_1 {
    width: 88px;
    height: 150px;
  }
  .pattern_2 {
    width: 77px;
    height: 254px;
  }
  &.pattern_wrapper_3 {
    ${LaternLight} {
      z-index: 5;
      mix-blend-mode: hard-light;
      margin-top: -156px;
      margin-left: -110px;
      animation: opacityLight 15s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }
    .pattern_3 {
      width: 185px;
      height: 351px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .pattern_3 {
        width: 135px;
        height: 281px;
      }
    }
    @media screen and (max-width: 767px) {
      &:nth-child(2) {
        display: none;
      }
      ${LaternLight} {
        margin-top: -146px;
      }
      .pattern_3 {
        width: 106px;
        height: 230px;
      }
    }
  }
  &.pattern_wrapper_4 {
    ${LaternLight} {
      z-index: 5;
      mix-blend-mode: hard-light;
      margin-top: -148px;
      margin-left: -110px;
      animation: opacityLight 15s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }
    .pattern_4 {
      width: 155px;
      height: 294px;
    }
    @media screen and (max-width: 1023px) {
      .pattern_4 {
        width: 105px;
        height: 214px;
      }
    }
  }
  @keyframes translate {
    0% {
      transform: rotate(0);
    }
    30% {
      transform: rotateZ(1deg);
    }
    60% {
      transform: rotateZ(-1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
