import {
  FooterContainer,
  FooterContent,
  FooterCopyRight,
  FooterSocials,
  FooterWrapper,
} from "./styled";
import twitter from "../../assets/Footer/Twitter.png";
import telegram from "../../assets/Footer/Telegram.png";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterContent>
          <FooterSocials>
            {socialList.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.href} target="_blank" rel="noreferrer">
                    <img loading="lazy" src={item.img} alt="icon" />
                  </a>
                </li>
              );
            })}
          </FooterSocials>
        </FooterContent>
        <FooterCopyRight>
          <small>版權所有 © 2024 MANEKO，保留所有權利</small>
        </FooterCopyRight>
      </FooterWrapper>
    </FooterContainer>
  );
};

const socialList = [
  {
    img: twitter,
    href: "https://twitter.com/ManekoBSC",
  },
  {
    img: telegram,
    href: "https://t.me/ManekoCoin",
  },
];

export default Footer;
