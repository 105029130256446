import styled from "styled-components";
import bg from "../../../assets/Home/Keyvisual/keyvisual_bg.png";

export const KeyvisualContainer = styled.div`
  position: relative;
  height: 250vh;
  z-index: 2;
  @media screen and (max-width: 1023px) {
    height: auto;
    overflow: hidden;
  }
`;
export const KeyvisualWrapper = styled.div`
  position: sticky;
  top: 0;
  @media screen and (max-width: 1023px) {
    position: relative;
  }
`;
export const KeyvisualInner = styled.div`
  background: url(${bg}) no-repeat center / cover;
  margin-top: -80px;
`;
export const KeyvisualSun = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  border-radius: 50%;
  background: linear-gradient(180deg, #ff4300 5.38%, #ffd500 105.26%);
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
`;
