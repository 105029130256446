import styled from "styled-components";
import frame_circle from "../../../assets/Home/Tokenomic/frame_circle.png";
import bg from "../../../assets/Home/Tokenomic/tokenomic_bg.png";
import frame_bg from "../../../assets/Home/Tokenomic/frame_bg.png";
import tokenomic_text_bg from "../../../assets/Home/Tokenomic/tokenomic_text_bg.png";
import pocket_1 from "../../../assets/Home/Tokenomic/pocket_1.png";
import pocket_2 from "../../../assets/Home/Tokenomic/pocket_2.png";
import cloud_1 from "../../../assets/Home/Tokenomic/cloud_1.png";
import cloud_before_1 from "../../../assets/Home/Tokenomic/cloud_before_1.png";
import cloud_2 from "../../../assets/Home/Tokenomic/cloud_2.png";
import cloud_before_2 from "../../../assets/Home/Tokenomic/cloud_before_2.png";

export const TokenomicContainer = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 360px;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat center / cover;
    mix-blend-mode: overlay;
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    &::before {
      top: 0;
    }
  }
`;
export const TokenomicWrapper = styled.div`
  position: relative;
  max-width: 1058px;
  padding: 0 10px;
  width: 100%;
  margin: -360px auto 0;
  @media screen and (max-width: 1023px) {
    margin: 0;
    padding: 0;
  }
`;
export const TokenomicCloud = styled.div`
  & > div {
    position: absolute;
    background: no-repeat center / 100% auto;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
    }
    &::after {
      mix-blend-mode: multiply;
    }
    &:nth-child(1) {
      left: -110px;
      bottom: 160px;
      width: 447px;
      height: 171px;
      &::before {
        width: 405px;
        height: 191px;
        background-image: url(${cloud_1});
        z-index: 2;
      }
      &::after {
        width: 447px;
        height: 171px;
        bottom: -30px;
        background-image: url(${cloud_before_1});
      }
    }
    &:nth-child(2) {
      right: 140px;
      bottom: 530px;
      &::before {
        width: 175px;
        height: 94px;
        bottom: 30px;
        background-image: url(${cloud_2});
        z-index: 2;
      }
      &::after {
        width: 186px;
        height: 109px;
        bottom: 0;
        background-image: url(${cloud_before_2});
      }
    }
  }
`;
export const TokenomicFrame = styled.div`
  position: relative;
  background: url(${frame_bg}) no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 285px;
  padding-bottom: 130px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    background: none;
    padding: 50px 10px;
    z-index: unset;
  }
`;
export const TokenomicTitle = styled.h2`
  max-width: 428px;
  width: 100%;
  margin: 20px auto 120px;
  mix-blend-mode: multiply;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 767px) {
    max-width: 250px;
    margin: 0 auto 20px;
    mix-blend-mode: lighten;
  }
`;
export const TokenomicContent = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    top: 50%;
  }
  &::before {
    width: 79px;
    height: 103px;
    background-image: url(${pocket_1});
    left: -80px;
    margin-top: 30px;
  }
  &::after {
    right: -50px;
    margin-top: -20px;
    width: 110px;
    height: 145px;
    background-image: url(${pocket_2});
  }
  @media screen and (max-width: 767px) {
    max-width: 360px;
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const FrameCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 494px;
  height: 340px;
  margin: 0 -50px;
  background: url(${frame_circle}) no-repeat center / 100% auto;
  @media screen and (max-width: 767px) {
    height: 265px;
    margin: 0 -10px;
  }
`;
export const FrameSubTitle = styled.h3`
  max-width: 196px;
  width: 100%;
  margin: 0 auto;
  mix-blend-mode: lighten;
  img {
    width: 100%;
  }
`;
export const FramePrice = styled.p`
  font-family: "Zhi Mang Xing", cursive;
  text-align: center;
  margin-bottom: 10px;
  font-size: 60px;
  line-height: 60px;
  background: linear-gradient(
    130deg,
    #ddb263 10.42%,
    #dfb565 12.56%,
    #ecc974 28.97%,
    #f5d57d 44.67%,
    #f8da81 60.37%,
    #f1d079 64.65%,
    #dfb566 70.36%,
    #c28b46 78.92%,
    #b67838 81.78%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 767px) {
    font-size: 50px;
  }
`;
export const FrameContract = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  gap: 20px;
  border-radius: 34px;
  background: #eb2420;
  margin-bottom: 10px;
  p {
    font-family: "Zhi Mang Xing", cursive;
    font-size: 21px;
    font-weight: bold;
    background: linear-gradient(
      130deg,
      #ddb263 10.42%,
      #dfb565 12.56%,
      #ecc974 28.97%,
      #f5d57d 44.67%,
      #f8da81 60.37%,
      #f1d079 64.65%,
      #dfb566 70.36%,
      #c28b46 101.92%,
      #b67838 105.78%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 18px;
    }
  }
`;
export const FrameValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;
export const ValueItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-height: 47px;
  p {
    position: relative;
    font-family: "Zhi Mang Xing", cursive;
    font-size: 22px;
    font-weight: bold;
    background: linear-gradient(
      130deg,
      #ddb263 10.42%,
      #dfb565 12.56%,
      #ecc974 28.97%,
      #f5d57d 44.67%,
      #f8da81 60.37%,
      #f1d079 64.65%,
      #dfb566 70.36%,
      #c28b46 101.92%,
      #b67838 105.78%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 47px;
    background: url(${tokenomic_text_bg}) no-repeat center / 100% 100%;
    z-index: 0;
    mix-blend-mode: plus-lighter;
  }
`;
export const TokenomicLatern = styled.div`
  position: absolute;
  top: 558px;
  max-width: 636px;
  width: 100%;
  left: 50%;
  margin-left: -336px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
