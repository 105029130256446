import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./page/Home";
import { AppContainer, BlossomGif } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc } from "viem/chains";
import Nfts from "./page/Nfts";
import Preloader from "./components/Preloader";
import { useEffect, useState } from "react";

const projectId = "17c32b7d53c43402b9413971337ef0b2";
const chains = [bsc];
const wagmiConfig: any = defaultWagmiConfig({ chains, projectId });
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "light",
});

const App = () => {
  const { pathname } = useLocation();
  const [removePreloader, setRemovePreloader] = useState(false);
  useEffect(() => {
    document.body.classList.add("hidden");
  }, []);

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <WagmiConfig config={wagmiConfig}>
      <ContextProvider>
        <Toaster position="top-right" reverseOrder={false} />
        {removePreloader ? (
          <></>
        ) : (
          <Preloader setRemovePreloader={setRemovePreloader} />
        )}
        <AppContainer home={pathname === "/"}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mint" element={<Nfts />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </AppContainer>
      </ContextProvider>
    </WagmiConfig>
  );
};

export default App;
