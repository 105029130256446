import styled from "styled-components";
import bg from "../../assets/Preloader/preloader_bg.png";
import decor from "../../assets/Preloader/preloader_decor.png";
import temple from "../../assets/Preloader/preloader_frame.png";
import door_left from "../../assets/Preloader/door_left.png";
import door_right from "../../assets/Preloader/door_right.png";
import door_key from "../../assets/Preloader/door_key.png";

export const PreloaderContainer = styled.div`
  background: url(${bg}) no-repeat center / 100% 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 2.5s ease-in-out;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: url(${decor}) no-repeat center / 100% 100%;
    width: 100%;
    height: 38px;
    left: 0;
    z-index: 2;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  &.open {
    transform: scale(4);
    visibility: hidden;
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      background-size: cover;
    }
  }
`;
export const PreloaderWrapper = styled.div`
  position: relative;
  max-width: 1045px;
  width: 100%;
  margin: 0 auto;
  min-height: 666px;
  background: url(${temple}) no-repeat center / 100% 100%;
  @media screen and (min-width: 2000px) {
    max-width: 60vw;
    min-height: 40vw;
  }
  @media screen and (max-width: 767px) {
    background-size: cover;
  }
`;
export const PreloaderTemple = styled.div`
`;
export const PreLoaderDoors = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -256px;
  margin-top: -160px;
  display: flex;
  max-width: 520px;
  z-index: -1;
  & > div {
    background: no-repeat center / 100% auto;
    min-height: 427px;
    transition: all 2s ease-in-out;
    &.left {
      width: 265px;
      background-image: url(${door_left});
    }
    &.right {
      width: 248px;
      background-image: url(${door_right});
    }
  }
  &.open {
    & > div {
      opacity: 0;
      &.left {
        transform: translateX(-250px);
      }
      &.right {
        transform: translateX(250px);
      }
    }
  }
  @media screen and (min-width: 2000px) {
    margin-left: -16vw;
    margin-top: -12vw;
    max-width: 31vw;
    & > div {
      min-height: 30vw;
      &.left {
        width: 53vw;
      }
      &.right {
        width: 50vw;
      }
    }
  }
`;
export const PreloaderKey = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -44px;
  margin-top: 10px;
  width: 101px;
  min-height: 101px;
  background: url(${door_key}) no-repeat center / 100% 100%;
  cursor: pointer;
  z-index: 5;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  visibility: visible;
  &:hover {
    transform: rotate(180deg);
  }
  &.open {
    opacity: 0;
    visibility: hidden;
  }
  @media screen and (min-width: 2000px) {
    margin-left: -3vw;
    margin-top: 1vw;
    width: 6vw;
    min-height: 6vw;
  }
`;
export const PreloaderLatern = styled.div`
  position: absolute;
  top: 175px;
  left: 50%;
  margin-left: -230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  z-index: 5;
  mix-blend-mode: hard-light;
  transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.open {
    opacity: 0;
  }
  @media screen and (max-width: 400px) {
    margin-left: -190px;
  }
`;
export const PreloaderCat = styled.div`
  position: absolute;
  bottom: 57px;
  left: 50%;
  margin-left: -240px;
  z-index: 5;
  max-width: 145px;
  width: 100%;
  animation: moving 20s linear infinite;
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
  img {
    width: 100%;
  }
  &.open {
    opacity: 0;
    visibility: hidden;
  }
  @keyframes moving {
    0% {
      transform: translate(0) rotate(0) rotateY(0);
    }
    8% {
      transform: translate(58px) rotate(5deg);
    }
    16% {
      transform: translate(116px) rotate(-5deg);
    }
    24% {
      transform: translate(174px) rotate(5deg);
    }
    30% {
      transform: translate(232px) rotate(-5deg);
    }
    36% {
      transform: translateX(290px) rotate(5deg);
    }
    42% {
      transform: translateX(350px) rotate(-5deg) rotateY(180deg);
    }
    48% {
      transform: translateX(290px) rotate(5deg) rotateY(180deg);
    }
    54% {
      transform: translate(232px) rotate(-5deg) rotateY(180deg);
    }
    60% {
      transform: translate(174px) rotate(5deg) rotateY(180deg);
    }
    66% {
      transform: translate(116px) rotate(-5deg) rotateY(180deg);
    }
    72% {
      transform: translate(100px) rotate(5deg) rotateY(180deg);
    }
    80% {
      transform: translate(70px) rotate(-5deg) rotateY(180deg);
    }
    88% {
      transform: translate(40px) rotate(5deg) rotateY(180deg);
    }
    96% {
      transform: translate(10px) rotate(-5deg) rotateY(180deg);
    }
    100% {
      transform: translate(0) rotate(0) rotateY(0);
    }
  }
  @media screen and (min-width: 2000px) {
    max-width: 8.5vw;
    bottom: 3vw;
  }
`;
