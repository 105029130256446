import { DecorationLeft, DecorationRight } from "./styled";
import path_1_left from "../../../../assets/Home/Keyvisual/Decor/Left/path_1_left.png";
import path_1_right from "../../../../assets/Home/Keyvisual/Decor/Right/path_1_right.png";
import path_2_left from "../../../../assets/Home/Keyvisual/Decor/Left/path_2_left.png";
import path_2_right from "../../../../assets/Home/Keyvisual/Decor/Right/path_2_right.png";
import path_3_left from "../../../../assets/Home/Keyvisual/Decor/Left/path_3_left.png";
import path_3_right from "../../../../assets/Home/Keyvisual/Decor/Right/path_3_right.png";
import path_cloud_left_1 from "../../../../assets/Home/Keyvisual/Decor/Left/path_cloud_left_1.png";
import path_cloud_left_2 from "../../../../assets/Home/Keyvisual/Decor/Left/path_cloud_left_2.png";
import path_cloud_right_1 from "../../../../assets/Home/Keyvisual/Decor/Right/path_cloud_right_1.png";
import path_cloud_right_2 from "../../../../assets/Home/Keyvisual/Decor/Right/path_cloud_right_2.png";
import path_fan from "../../../../assets/Home/Keyvisual/Decor/path_fan.png";
import { motion } from "framer-motion";
import { KeyvisualSun } from "../styled";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../../components/Context";

const Decoration = ({ progressValue }: any) => {
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <>
      <DecorationLeft>
        {!isMobile && (
          <motion.img
            animate={{
              x:
                progressValue >= 0.2 && isDesktop
                  ? 0
                  : !isDesktop
                  ? "-30%"
                  : "-110%",
            }}
            className={
              progressValue === 1 && isDesktop ? "path_1 animate" : "path_1"
            }
            src={path_1_left}
            alt="1"
          />
        )}

        {isDesktop && (
          <motion.img
            animate={{ x: progressValue >= 0.4 && isDesktop ? 0 : "-110%" }}
            className={
              progressValue === 1 && isDesktop
                ? "path_cloud_1 animate"
                : "path_cloud_1"
            }
            src={path_cloud_left_1}
            alt="1"
          />
        )}
        {isDesktop && (
          <motion.img
            animate={{ x: progressValue >= 0.3 && isDesktop ? 0 : "-120%" }}
            className={
              progressValue === 1 && isDesktop ? "path_2 animate" : "path_2"
            }
            src={path_2_left}
            alt="2"
          />
        )}
        <motion.img
          animate={{
            x:
              progressValue >= 0.7 && isDesktop
                ? 0
                : !isDesktop
                ? "-30%"
                : "-120%",
          }}
          className={
            progressValue === 1 && isDesktop
              ? "path_cloud_2 animate"
              : "path_cloud_2"
          }
          src={path_cloud_left_2}
          alt="2"
        />
        <motion.img
          animate={{
            x:
              progressValue >= 0.6 && isDesktop
                ? 0
                : !isDesktop
                ? "-30%"
                : "-120%",
          }}
          className={
            progressValue === 1 && isDesktop ? "path_3 animate" : "path_3"
          }
          src={path_3_left}
          alt="3"
        />
        {isDesktop && (
          <motion.img
            className={
              progressValue === 1 && isDesktop ? "path_fan active" : "path_fan"
            }
            src={path_fan}
            alt="fan"
          />
        )}
      </DecorationLeft>
      <DecorationRight>
        {!isMobile && (
          <motion.img
            animate={{
              x:
                progressValue >= 0.2 && isDesktop
                  ? 0
                  : !isDesktop
                  ? "30%"
                  : "120%",
            }}
            className={
              progressValue === 1 && isDesktop ? "path_1 animate" : "path_1"
            }
            src={path_1_right}
            alt="1"
          />
        )}

        {isDesktop && (
          <motion.img
            animate={{ x: progressValue >= 0.4 && isDesktop ? 0 : "120%" }}
            className={
              progressValue === 1 && isDesktop
                ? "path_cloud_1 animate"
                : "path_cloud_1"
            }
            src={path_cloud_right_1}
            alt="1"
          />
        )}
        {isDesktop && (
          <motion.img
            animate={{ x: progressValue >= 0.3 && isDesktop ? 0 : "120%" }}
            className={
              progressValue === 1 && isDesktop ? "path_2 animate" : "path_2"
            }
            src={path_2_right}
            alt="2"
          />
        )}
        <motion.img
          animate={{
            x:
              progressValue >= 0.7 && isDesktop
                ? 0
                : !isDesktop
                ? "30%"
                : "120%",
          }}
          className={
            progressValue === 1 && isDesktop
              ? "path_cloud_2 animate"
              : "path_cloud_2"
          }
          src={path_cloud_right_2}
          alt="2"
        />
        <motion.img
          animate={{
            x:
              progressValue >= 0.6 && isDesktop
                ? 0
                : !isDesktop
                ? "30%"
                : "120%",
          }}
          className={
            progressValue === 1 && isDesktop ? "path_3 animate" : "path_3"
          }
          src={path_3_right}
          alt="3"
        />
        {isDesktop && (
          <motion.img
            className={
              progressValue === 1 && isDesktop ? "path_fan active" : "path_fan"
            }
            src={path_fan}
            alt="fan"
          />
        )}
      </DecorationRight>
      {isDesktop && (
        <KeyvisualSun
          style={{
            width:
              progressValue >= 0.4 && isDesktop
                ? `${progressValue * 120}%`
                : "0",
            height:
              progressValue >= 0.4 && isDesktop
                ? `${progressValue * 160}%`
                : "0",
            opacity:
              progressValue <= 0.4 && isDesktop ? `${progressValue}` : ".3",
            transform:
              progressValue >= 0.9 && isDesktop
                ? "translate(-50%, -150px) scale(1.2)"
                : " translateX(-50%) scale(1)",
          }}
        />
      )}
    </>
  );
};

export default Decoration;
