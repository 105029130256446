import styled from "styled-components";
import bg from '../../../assets/Home/Partner/partner_bg.png'
import marquee_bg from '../../../assets/Home/Partner/marquee_item.png'

export const PartnerContainer = styled.div`
  position: relative;
  padding-bottom: 260px;
  z-index: 4;
  background: #AF2623;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat top / cover;
    mix-blend-mode: overlay;
    z-index: 0;
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    padding-bottom: 100px;
  }
`;
export const PartnerWrapper = styled.div`
  position: relative;
  padding-top: 60px;
`;
export const PartnerTitle = styled.h2`
  max-width: 555px;
  width: 100%;
  margin: 0 auto 40px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1023px) {
    max-width: 375px;
  }
`;
export const PartnerItem = styled.div`
  max-width: 343px;
  margin: 0 10px;
  img {
    &:nth-child(1) {
      width: 72px;
      height: 72px;
    }
    &:nth-child(2) {
      width: 228px;
      height: auto;
    }
  }
  a {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 290px;
    img {
      &:nth-child(1) {
        width: 56px;
        height: 56px;
      }
      &:nth-child(2) {
        width: 168px;
      }
    }
  }
`;
export const MarqueeList = styled.div`
  /* mix-blend-mode: plus-lighter; */
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;
