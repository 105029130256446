import {
  AboutCloud,
  AboutContainer,
  AboutDescribe,
  AboutFrame,
  AboutLatern,
  AboutLinks,
  AboutTitle,
  AboutWrapper,
  CloudItem,
} from "./styled";
import title from "../../../assets/Home/About/about_title.png";
import LaternPattern from "../../../components/Latern";
import logo_1 from "../../../assets/Home/About/logo_1.png";
import logo_2 from "../../../assets/Home/About/logo_2.png";
import logo_3 from "../../../assets/Home/About/logo_3.png";
import logo_4 from "../../../assets/Home/About/logo_4.svg";
import logo_5 from "../../../assets/Home/About/logo_5.svg";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

const About = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <AboutContainer>
      {!isMobile && (
        <AboutCloud>
          <CloudItem />
          <CloudItem />
          <CloudItem />
          <CloudItem />
        </AboutCloud>
      )}
      <AboutWrapper>
        <AboutFrame>
          {!isMobile && (
            <AboutLatern>
              <LaternPattern pattern={2} />
              <LaternPattern pattern={2} />
            </AboutLatern>
          )}
          <AboutTitle>
            <img loading="lazy" src={title} alt="title" />
          </AboutTitle>
          <AboutDescribe>
            有了Maneko，我们带来的不仅仅是投资，还有体验快乐和幸运的机会。让我们分享对招猫币的热情，享受每天不断发展的社区的积极氛围。
          </AboutDescribe>
          <AboutLinks>
            {linkData.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.href} target="_blank" rel="noreferrer">
                    <figure>
                      <img loading="lazy" src={item.icon} alt="icon" />
                    </figure>
                  </a>
                </li>
              );
            })}
          </AboutLinks>
        </AboutFrame>
      </AboutWrapper>
    </AboutContainer>
  );
};

const linkData = [
  {
    icon: logo_1,
    href: "#",
  },
  {
    icon: logo_2,
    href: "#",
  },
  {
    icon: logo_3,
    href: "#",
  },
  {
    icon: logo_4,
    href: "https://www.pinksale.finance/launchpad/0x24b6296a601aA62f5b78608ed1F1eA06A01Db667?chain=BSC",
  },
  {
    icon: logo_5,
    href: "https://www.dexview.com/bsc/0xDA7C2d9c977ce750438Ea3f7e81134aE115A1f35",
  },
];

export default About;
