import { LuckyCatContainer } from "./styled";
import lucky_cat from '../../assets/LuckyCat/lucky_cat.png'

const LuckyCat = () => {
  return <LuckyCatContainer>
    <img loading="lazy" src={lucky_cat} alt="lucky-cat" />
  </LuckyCatContainer>;
};

export default LuckyCat;
