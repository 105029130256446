import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";
import header_bg from "../../assets/Header/header_bg.png";
import buttonCW from "../../assets/Header/buttonCW.png";

export const HeaderBody = styled.div<{ home?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgba(205, 205, 205, 0.44);
  background: ${({ home }) =>
    home ? `url(${header_bg}) no-repeat top / 100% 100%` : "unset"};
  transition: all 0.5s ease;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 1;
  }
`;
export const HeaderContainer = styled.header`
  position: relative;
  max-width: 1140px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  z-index: 2;
  @media screen and (max-width: 1024px) {
    width: auto;
    padding: 15px 10px;
    gap: 15px;
  }
`;
export const HeaderLogo = styled.div`
  width: 210px;
  height: auto;
  flex-shrink: 0;
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
  }
  @media screen and (max-width: 767px) {
    width: 140px;
  }
`;
export const HeaderLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  & > li {
    display: block;
    a {
      display: block;
      text-decoration: none;
      font-size: 18px;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      color: #fff;
      &:hover {
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
          0 0 10px rgba(255, 255, 255, 0.5);
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1396px) {
    gap: 15px;
    & > li {
      a {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    gap: 50px;
    padding: 50px 0;
    & > li {
      a {
        font-size: 25px;
      }
    }
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  &.menu-fixed {
    position: fixed;
    justify-content: center;
    left: 0;
    top: 80px;
    background: #000;
    color: #fff;
    transition: transform 0.75s ease;
    transform: translateX(150%);
    z-index: -1;
    overflow-y: auto;
    ${HeaderLinks} {
      flex-direction: column;
    }
    ${ButtonCommon} {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1023px) {
    &.menu-fixed {
      top: 93px;
      background: rgb(0, 0, 0, 0.7);
      backdrop-filter: blur(13px);
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100vh - 80px);
    }
  }
  @media screen and (max-width: 767px) {
    &.menu-fixed {
      top: 81px;
    }
  }
`;
export const HeaderInner = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
  @media screen and (max-width: 1023px) {
    overflow-y: auto;
    height: auto;
    width: 100%;
    padding: 0;
  }
`;
export const ButtonHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  backdrop-filter: blur(20px);
  cursor: pointer;
  & > span {
    position: relative;
    display: block;
    width: 28px;
    height: 2px;
    background-color: #fff;
    transition: transform 0.25s ease;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &.active {
    & > span {
      &:nth-child(1) {
        top: 8px;
        transform: rotateZ(45deg);
      }
      &:nth-child(2) {
        top: -6px;
        transform: rotateZ(-45deg);
      }
    }
  }
`;
export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-shrink: 0;
`;
export const ActionVolumn = styled.div``;
export const MobileAction = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
`;
export const ButtonConnectWallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  min-height: 57px;
  background: url(${buttonCW}) no-repeat center / 100% auto;
  cursor: pointer;
  p {
    -webkit-text-stroke-width: 1px;
    color: #fff;
    -webkit-text-stroke-color: #000;
    font-size: 15px;
    mix-blend-mode: overlay;
  }
  @media screen and (max-width: 767px) {
    width: 200px;
    min-height: 80px;
    p {
      padding-top: 15px;
    }
  }
`;
export const ButtonAudit = styled.div`
  max-width: 161px;
  width: 100%;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: 100px;
  }
`