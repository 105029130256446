import styled from "styled-components";
import button_bg from "../assets/Button/button.png";
import button_bg_big from "../assets/Button/button_big.png";
import button_bg_big_mint from "../assets/Button/button_big_mint.png";

export const ButtonCommon = styled.div`
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  &.small {
    width: 154px;
    min-height: 57px;
    background: url(${button_bg}) no-repeat center / 100% 100%;
  }
  &.big {
    width: 215px;
    min-height: 79px;
    background: url(${button_bg_big}) no-repeat center / 100% 100%;
    &.mint {
      background-image: url(${button_bg_big_mint});
    }
  }
`;
