import {
  DescribeWrapper,
  MapScrollContainer,
  ScrollDescribe,
  ScrollItem,
  ScrollLight,
  ScrollList,
  ScrollTitle,
  ScrollWrapper,
} from "../styled";

const ScrollMap = ({ scrollData, openMap, mapContent, fadeItem }: any) => {
  return (
    <MapScrollContainer className={openMap ? "open" : ""}>
      <ScrollWrapper className={openMap ? "open" : ""}>
        <ScrollList className={mapContent ? "open" : ""}>
          {scrollData.map((item: any, index: any) => {
            return (
              <ScrollItem className={fadeItem ? "fadeItem" : ""} key={index}>
                <ScrollTitle>{item.title}</ScrollTitle>
                <DescribeWrapper>
                  <ScrollLight />
                  <ScrollDescribe>
                    {item.describe.map((des: any, index: any) => {
                      return <li key={index}>{des}</li>;
                    })}
                  </ScrollDescribe>
                </DescribeWrapper>
                <ScrollLight className="small" />
              </ScrollItem>
            );
          })}
        </ScrollList>
      </ScrollWrapper>
    </MapScrollContainer>
  );
};

export default ScrollMap;
