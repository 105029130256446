import styled from "styled-components";
import title_bg from "../../../assets/Home/Card/Decor/title_bg.png";
import { ButtonCommon } from "../../../Layout/styled";
import card_bg from "../../../assets/Home/Card/Decor/card_bg.jpg";
import decor_1 from "../../../assets/Home/Card/Decor/decor_1.png";
import decor_2 from "../../../assets/Home/Card/Decor/decor_2.png";
import { ScrollDescribe } from "../Map/styled";

export const CardContainer = styled.div`
  position: relative;
  background-color: #fdeea5;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  &::before {
    background: rgba(100, 0, 0, 0.35);
    backdrop-filter: blur(7px);
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &::after {
    background: url(${card_bg}) no-repeat center / cover;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  @media screen and (max-width: 767px) {
    min-height: auto;
    padding: 100px 0 70px;
  }
`;
export const CardWrapper = styled.div`
  position: relative;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  z-index: 3;
  ${ButtonCommon} {
    margin: 0 auto;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    z-index: 5;
  }
  &::before {
    background-image: url(${decor_1});
    width: 313px;
    height: 320px;
    bottom: -120px;
    left: -120px;
    animation: movingBefore 3s linear infinite;
  }
  &::after {
    background-image: url(${decor_2});
    width: 390px;
    height: 309px;
    bottom: -120px;
    right: -140px;
    animation: movingAfter 3s linear infinite;
  }
  @keyframes movingBefore {
    0% {
      transform: translate(0);
    }
    30% {
      transform: translate(10px, 10px);
    }
    60% {
      transform: translate(10px, 20px);
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes movingAfter {
    0% {
      transform: translate(0);
    }
    30% {
      transform: translate(10px, -10px);
    }
    60% {
      transform: translate(-10px, 20px);
    }
    100% {
      transform: translate(0);
    }
  }
  @media screen and (max-width: 1023px) {
    &::before {
      width: 183px;
      height: 210px;
      left: 0;
    }
    &::after {
      width: 250px;
      height: 209px;
      right: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const CardList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
  & > div {
    width: 33.33%;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
    & > div {
      max-width: 280px;
      width: 100%;
    }
  }
`;
export const CardTitle = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 177px;
  width: 100%;
  margin: 0 auto -20px;
  min-height: 53px;
  background: url(${title_bg}) no-repeat center / 100% 100%;
  color: #fff;
  text-align: center;
  font-size: 17px;
`;
export const CardImage = styled.figure`
  background-color: #ffd092;
  border-radius: 20px;
  padding: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
`;
export const CardButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
`;
export const CardNote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: "Zhi Mang Xing", cursive;
  color: #fff;
  gap: 10px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`;
export const OverlayCardContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 1s ease-in-out;
  ${ScrollDescribe} {
    font-size: 12px;
    font-family: SaintRegus;
    text-shadow: none;
  }
`;
export const OverlayBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
`;
