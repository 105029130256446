import styled from "styled-components";
import bg from "../../assets/LuckyCat/bg_luckycat.png";
import circle from "../../assets/LuckyCat/circle.png";

export const LuckyCatContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 138px;
  z-index: 5;
  background: #AF2623 url(${bg}) repeat top / auto 100%;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    width: 297px;
    height: 297px;
    margin-top: -148px;
    margin-left: -148px;
    background: url(${circle}) repeat top / auto 100%;
  }
  &::after {
    width: 366px;
    height: 356px;
    margin-left: -183px;
    margin-top: -173px;
    border-radius: 50%;
    opacity: 0.62;
    background: #FF3600;
    mix-blend-mode: plus-lighter;
    filter: blur(88px);
  }
  img {
    position: absolute;
    top: -76px;
    left: 50%;
    width: 316px;
    height: 292px;
    margin-left: -156px;
    z-index: 3;
  }
  @media screen and (max-width: 1023px) {
    height: 95px;
    &::before {
      width: 210px;
      height: 210px;
      margin-top: -105px;
      margin-left: -105px;
    }
    &::after {
      width: 236px;
      height: 236px;
      margin-top: -118px;
      margin-left: -118px;
    }
    img {
      top: -42px;
      width: 196px;
      height: 182px;
      margin-left: -98px;
    }
  }
`;
