import { useEffect, useState } from "react";
import { TimeCountList } from "./styled";

const TimeCount = () => {
  const timestamp = 1706864400;
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(timestamp)
  );

  const formatTime = (time: any) => {
    return time < 10 ? `${time}` : `${time}`;
  };

  const formatRemainingTime = () => {
    const date = new Date(remainingTime * 1000); // Convert seconds to milliseconds
    const days = formatTime(date.getUTCDate() - 1); // Subtract 1 because getUTCDate returns day of the month
    const hours = formatTime(date.getUTCHours());
    const minutes = formatTime(date.getUTCMinutes());
    const seconds = formatTime(date.getUTCSeconds());

    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatRemainingTime();

  useEffect(() => {
    if (timestamp) {
      const interval = setInterval(() => {
        const newRemainingTime = calculateRemainingTime(timestamp);
        setRemainingTime(newRemainingTime);
      }, 50);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timestamp]);

  function calculateRemainingTime(timestamp: any) {
    const currentTime = Math.floor(Date.now() / 1000);
    return timestamp - currentTime;
  }
  return (
    <TimeCountList>
      <li>
        <p>{days}D</p>
      </li>
      <li>
        <p>{hours}H</p>
      </li>
      <li>
        <p>{minutes}M</p>
      </li>
      <li>
        <p>{seconds}S</p>
      </li>
    </TimeCountList>
  );
};

export default TimeCount;
