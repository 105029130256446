import LuckyCat from "../../components/Lucky";
import Preloader from "../../components/Preloader";
import About from "./About";
import Card from "./Card";
import Keyvisual from "./Keyvisual";
import Map from "./Map";
import Partner from "./Partner";
import Tokenomic from "./Tokenomic";
import { HomeContainer } from "./styled";

const Home = () => {
  return (
    <HomeContainer>
      <Keyvisual />
      <Card />
      <LuckyCat />
      <About />
      <Map />
      <Tokenomic />
      <Partner />
      <LuckyCat />
    </HomeContainer>
  );
};

export default Home;
