import styled from "styled-components";
import { LaternLight } from "../../../../components/Latern/styled";

export const KeyvisualContentContainer = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 110px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 82px;
    height: 85vh;
  }
`;
export const KeyvisualLaternLight = styled.div`
  position: absolute;
  left: 50%;
  top: 80px;
  max-width: 1200px;
  width: 100%;
  margin-left: -600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  mix-blend-mode: hard-light;
  z-index: 5;
  .pattern_wrapper_4 {
    top: -34px;
  }
  & > div {
    &:nth-child(1) {
      ${LaternLight} {
        animation-delay: 2s;
      }
    }
    &:nth-child(2) {
      ${LaternLight} {
        animation-delay: 3s;
      }
    }
    &:nth-child(3) {
      ${LaternLight} {
        animation-delay: 2s;
      }
    }
    &:nth-child(4) {
      ${LaternLight} {
        animation-delay: 0.5s;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    left: 50%;
    margin-left: 0;
    transform: translateX(-50%);
    top: 0;
    max-width: max-content;
    width: auto;
    z-index: 15;
    .pattern_wrapper_4 {
      top: -8px;
    }
  }
`;
export const KeyvisualLogo = styled.div`
  position: relative;
  max-width: 461px;
  width: 100%;
  margin: 0 auto 20px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1023px) {
    max-width: 360px;
  }
`;
export const KeyvisualText = styled.div`
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  font-family: SaintRegus;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 3.071px rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    width: auto;
    padding: 0 10px;
  }
`;
