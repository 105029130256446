import styled from "styled-components";
import flower_wrapper from "../../../../assets/Home/Card/Decor/flower_warpper.png";

export const FlowerDecorContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  top: -62px;
`;
export const FlowerWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 570px;
  margin: 0 auto;
  background: url(${flower_wrapper}) no-repeat center / 100% auto;
  img {
    position: relative;
    top: 10px;
    width: 101px;
    height: 101px;
    margin: 0 auto;
    animation: rotate 5s infinite linear;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
